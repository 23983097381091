import { mailRegex } from "../../../utils/mailRegex";

export const isValidEmail = (string: string) => {
  return mailRegex.test(string);
};

export const isPasswordStrong = (password: string) => {
  return !/^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/.test(password);
};

// export const isPasswordStrong = (password: string) => {
//   return !/^(.{0,15}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/.test(password);
// };

export const hasSpecialChar = (password: string) => {
  return /\W/.test(password);
};

export const hasUppercase = (password: string) => {
  return /[A-Z]/.test(password);
};

export const hasLowercase = (password: string) => {
  return /[a-z]/.test(password);
};

export const hasNumber = (password: string) => {
  return /[0-9]/.test(password);
};

export const has8Characters = (password: string) => {
  return password?.trim().length >= 8;
};

export const has16Characters = (password: string) => {
  return password?.trim().length >= 16;
};
