import { FlatActionButton } from "@caisy/league";
import Link from "next/link";
import React from "react";
import { useI18nLogin } from "../../../provider/i18n/I18nProvider";
import { ListenerEntrypoint } from "../../../stores/entrypoint/useEntrypoint";
import LogoFull from "../../atom/logoFull/LogoFull";
import { SLoginHeader } from "./styles/SLoginHeader";
import { SLoginHeaderWrapper } from "./styles/SLoginHeaderWrapper";

interface ILoginHeader {}

export const LoginHeader: React.FC<ILoginHeader> = () => {
  const labels = useI18nLogin();

  return (
    <SLoginHeader>
      <ListenerEntrypoint />
      <LogoFull />
      <SLoginHeaderWrapper>
        <p>{labels?.welcome_headerNoAccount ?? "Don’t have an account?"}</p>
        {/* <FlatActionButton
          type="grey"
          onClick={() => {
            console.log("is still todo");
          }}
        >
          {labels?.welcome_signUp ?? "Sign up"}
        </FlatActionButton> */}
        {process.env.NEXT_PUBLIC_NO_SIGN_UP === "true" ? (
          <a href="https://caisy.io/book-a-demo" rel="noreferrer" target={"_blank"}>
            <FlatActionButton type="grey">{"Book onboarding"}</FlatActionButton>
          </a>
        ) : (
          <Link href="/signup">
            <FlatActionButton type="grey">{labels?.welcome_signUp ?? "Sign up"}</FlatActionButton>
          </Link>
        )}
      </SLoginHeaderWrapper>
    </SLoginHeader>
  );
};
