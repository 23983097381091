import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../../constants/styles/mediaquerys";

const Default = css`
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  margin: auto;
`;

const Silver = css`
  width: 86.8%;
`;

const Gold = css`
  padding: 0;
`;

const Platinum = css``;

const Diamond = css`
  width: 59%;
`;

export const SLoginContent = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
