import { CSSProgressiveHeading02 } from "@caisy/league";
import { CSSProperties } from "react";
import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../../constants/styles/mediaquerys";

const Default = css<{ styleOverwrite?: CSSProperties }>`
  margin: 0 auto 24px;
  ${CSSProgressiveHeading02};
  color: var(--text-01);
  text-align: center;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.02em;
  ${({ styleOverwrite }) => (styleOverwrite ? styleOverwrite : "")}
`;

const Silver = css``;

const Gold = css`
  font-size: 40px;
  line-height: 52px;
`;

const Platinum = css``;

const Diamond = css``;

export const SLoginTitle = styled.p`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
