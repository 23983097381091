import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../../constants/styles/mediaquerys";

type IColors = "orange" | "lime";
const colors = {
  orange: "linear-gradient(102.45deg, #FF8A00 16.49%, #EF7301 89.22%)",
  lime: "linear-gradient(102.45deg, #9EFF00 16.49%, #01EF6F 89.22%)",
  violet: "linear-gradient(102.45deg, #6100FF 16.49%, #8000FF 89.22%);",
};
const Default = css<{ color: IColors }>`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ color }) => colors[color]};
  border-radius: 50%;
  color: var(--icon-02);
  width: fit-content;
  margin: 0 auto 24px;
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SLoginIconWrapper = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
